@import "avatar.css";
@import "highlight.css";

body {
  font-family: "Noto Sans", sans-serif;
  font-size: 10pt;
}

header {
  background-color: #007;
  color: #fff;
  font-size: 16pt;
  font-weight: normal;
  padding: 16px;
}

header a {
  color: #fff;
}

header figure {
  float: right;
  margin: 0;
  position: relative;
  top: -5px;
}

article {
  margin: 0;
  padding: 0 16px;
}

article h1 {
  font-size: 12pt;
  font-weight: bold;
  margin-bottom: 0;
  text-decoration: underline;
}

a {
  color: #007;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.content h1 {
  text-decoration: none;
}

.content h2 {
  font-size: 10pt;
  text-decoration: underline;
}

.content h3 {
  font-size: 10pt;
}

.content h4 {
  font-weight: normal;
  text-decoration: underline;
}

.content .caption {
  background-color: #f8f8ff;
  font-weight: bold;
  padding: 8px;
}

.content code {
  font-family: "Noto Sans Mono", monospace;
  font-size: 8pt;
}

.content figure {
  margin: 0;
}

.content pre {
  background-color: #ddf;
  margin-top: 0;
  overflow-x: auto;
  padding: 8px;
}

.home-links {
  font-size: 32pt;
  padding: 0 8px;
}

.software-job-search-div {
  overflow-x: auto;
  text-align: center;
}

.software-job-search-iframe {
  border: none;
  display: flex;
  height: 100%;
  min-height: 64vh;
  width: 100%;
}

.dos-boot-sector-table {
  text-align: center;
}

.dos-boot-sector-table table {
  border-collapse: collapse;
  margin: 8px auto;
  width: 640px;
}

.dos-boot-sector-table td {
  border: 1px solid #007;
  height: 20px;
  margin: 0;
  padding: 0;
  width: 20px;
}

.dos-boot-sector-table-partially-reserved-byte {
  background-color: #99f;
}

.dos-boot-sector-table-reserved-byte {
  background-color: #339;
}

.dos-boot-sector-table caption {
  text-align: center;
}

.dos-boot-sector-parameters + table {
  border-collapse: collapse;
  margin: auto;
}

.dos-boot-sector-parameters + table thead {
  background-color: #007;
  color: #fff;
  font-weight: bold;
}

.dos-boot-sector-parameters + table thead tr th {
  min-width: 4em;
  text-align: left;
}

.dos-boot-sector-parameters + table tbody tr td:first-child {
  text-align: center;
}
